/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';
import { usePageContext } from '../../hooks/context/usePageContext';
import { SanityMetadata, SiteMetadataQuery } from '../../types/graphql-types';

type MetaProps = JSX.IntrinsicElements['meta'];

interface Props {
  rawMeta?: SanityMetadata;
  meta?: MetaProps[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  scripts?: any;
  path?: string | null;
}

export const SEO: React.FC<Props> = ({ rawMeta, meta = [], scripts = [], path = '' }) => {
  const { site, sanitySettings } = useStaticQuery<SiteMetadataQuery>(METAQUERY);
  const { lang, slugs, originalPath, defaultLang } = usePageContext();

  /*
   Donnees qui proviennent du gatsby-config
  */

  const defaultLanguage = site?.siteMetadata?.defaultLanguage as string;
  const extraLanguages = site?.siteMetadata?.extraLanguages as string[];

  /*
    Donnees qui proviennent des props (SEO specifique) ou Fallback (sanity query settings) 
  */

  const host = sanitySettings?.domainName ?? '';

  const websiteTitle =
    sanitySettings?._rawMetaTitle?.[lang] || sanitySettings?._rawMetaTitle?.[defaultLanguage];

  const metaTitle = rawMeta?.metaTitle ? `${rawMeta?.metaTitle} | ${websiteTitle}` : websiteTitle;

  const metaDescription =
    rawMeta?.metaDescription ||
    sanitySettings?._rawMetaDescription?.[lang] ||
    sanitySettings?._rawMetaDescription?.[defaultLanguage] ||
    '';

  const ogTitle =
    rawMeta?.ogTitle ||
    sanitySettings?._rawOgTitle?.[lang] ||
    sanitySettings?._rawOgTitle?.[defaultLanguage] ||
    metaTitle ||
    '';

  const ogDescription =
    rawMeta?.ogDescription ||
    sanitySettings?._rawOgDescription?.[lang] ||
    sanitySettings?._rawOgDescription?.[defaultLanguage] ||
    metaDescription ||
    '';

  const ogImage = rawMeta?.ogImage?.asset?.url || sanitySettings?.ogImage?.asset?.url || '';

  const twitterTitle =
    rawMeta?.twitterTitle ||
    sanitySettings?._rawTwitterTitle?.[lang] ||
    sanitySettings?._rawTwitterTitle?.[defaultLanguage] ||
    metaTitle ||
    '';

  const twitterDescription =
    rawMeta?.twitterDescription ||
    sanitySettings?._rawTwitterDescription?.[lang] ||
    sanitySettings?._rawTwitterDescription?.[defaultLanguage] ||
    metaDescription ||
    '';

  const twitterImage =
    rawMeta?.twitterImage?.asset?.url || sanitySettings?.twitterImage?.asset?.url || ogImage;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      script={scripts}
      title={metaTitle}
      // titleTemplate={
      //   metaTitle === websiteTitle ? `%s` : `%s | ${websiteTitle || ""}`
      // }
      meta={[
        // { name: `theme-color`, content: `#0d0e0d` },
        // { name: `apple-mobile-web-app-capable`, content: `yes` },
        // {
        //   name: `apple-mobile-web-app-status-bar-style`,
        //   content: `black-translucent`,
        // },
        {
          name: `description`,
          content: metaDescription,
        },
        {
          /* This is the title of your webpage. Remember that this will be shown whenever someone links your website, so make it quick, snappy and able to draw attention */
          property: `og:title`,
          content: ogTitle,
        },
        {
          property: `og:description`,
          content: ogDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: ogImage,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:title`,
          content: twitterTitle,
        },
        {
          name: `twitter:description`,
          content: twitterDescription,
        },
        {
          name: `twitter:image`,
          content: twitterImage,
        },
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
      ].concat(meta)}
      link={[
        /* 
         https://developers.google.com/search/docs/advanced/crawling/localized-versions?hl=en
         Le cannonical link c'est un lien vers soi meme, toutes les pages doivent en avoir un.
         /!\ une page d'une autre langue pointe vers sa propre version pas vers la langue par defaut
 
         Les alternate links ce sont TOUTES les versions de cette page dans d'autres langue MEME la langue actuelle.
         Le x-default est un fallback pour toutes les langues non gerees, la bonne pratique c'est de mettre un lien vers la page ou on peut choisir la langue du site.
         */
        {
          rel: 'canonical',
          href:
            lang === defaultLanguage ? `${host}/${originalPath}` : `${host}/${lang}${originalPath}`,
          hrefLang: lang,
        },
        {
          rel: 'alternate',
          hrefLang: 'x-default',
          href: `${host}/${slugs?.find((slug) => slug.lang === defaultLang)?.slug ?? path}`,
        },
        {
          rel: 'alternate',
          hrefLang: defaultLanguage,
          href: `${host}/${slugs?.find((slug) => slug.lang === defaultLang)?.slug ?? path}`,
        },
        ...extraLanguages.map((extraLang: string) => ({
          rel: 'alternate',
          hrefLang: extraLang,
          href: `${host}/${extraLang}/${
            slugs?.find((slug) => slug.lang === extraLang)?.slug ?? path
          }`,
        })),
      ]}
    />
  );
};

const METAQUERY = graphql`
  query SiteMetadata {
    site {
      siteMetadata {
        extraLanguages
        defaultLanguage
      }
    }

    sanitySettings: sanitySiteMetadata {
      _rawMetaDescription
      _rawMetaTitle
      _rawTwitterDescription
      _rawTwitterTitle
      _rawOgDescription
      _rawOgTitle
      ogImage {
        asset {
          url
        }
      }
      twitterImage {
        asset {
          url
        }
      }
      domainName
    }
  }
`;
