import React from 'react';
import { Script } from 'gatsby';

export const GoogleTagManagerHead = () => {
  if (process.env.NODE_ENV === `production`)
    return (
      <>
        <script
          key="partytown-vanilla-config"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: `partytown = {
           resolveUrl(url, location) {
              if (url.hostname.includes('google-analytics')) {
                // Use a secure connection
                if (url?.protocol === 'http:') {
                  url = new URL(url.href.replace('http', 'https'))
                }

                // Point to our proxied URL
                const proxyUrl = new URL(location.origin + '/__third-party-proxy')
                proxyUrl.searchParams.append('url', url)

                return proxyUrl
              }

              return url
           }
         }`,
          }}
        />
        <Script strategy="off-main-thread" forward={[`dataLayer.push`]}>
          {`<!-- Google Tag Manager -->
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${process.env.GATSBY_GTM}');
            <!-- End Google Tag Manager -->`}
        </Script>
      </>
    );

  return null;
};
