import React, { useState, useContext, createContext, useMemo, useEffect } from 'react';
import { navigate } from 'gatsby';
import { SnackBarContextProvider } from './useSnackBarContext';

type IPageContext = {
  lang: string;
  setLang: (s: string) => void;
  slugs: Slug[];
  originalPath: string;
  defaultLang: string;
  footerColor?: string;
};

type GatsbyContext = {
  slugs: Slug[];
  originalPath: string;
  lang: string;
  footerColor?: string;
};

type Slug = { slug: string; lang: string };
const defaultLang = 'fr';

const PageContext = createContext<IPageContext | undefined>(undefined);

export const PageContextProvider = ({
  pageContext,
  children,
}: {
  pageContext: GatsbyContext;
  children: React.ReactNode;
}) => {
  const [lang, setLang] = useState(pageContext.lang);
  const slugs: Slug[] = pageContext?.slugs;
  const originalPath = pageContext?.originalPath;
  const footerColor = pageContext?.footerColor;

  useEffect(() => {
    const index = slugs?.findIndex((slug) => slug.lang === lang);
    if (index !== -1 && slugs?.[index]?.slug === '/') {
      const slug = lang === defaultLang ? '/' : `/${lang}`;
      navigate(slug);
    } else if (index !== -1 && slugs?.[index]?.slug) {
      const slug =
        lang === defaultLang ? `/${slugs?.[index]?.slug}` : `/${lang}/${slugs?.[index]?.slug}`;
      navigate(slug);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);

  const value = useMemo(
    () => ({ lang, setLang, slugs, originalPath, defaultLang, footerColor }),
    [lang, slugs, originalPath, footerColor]
  );

  return (
    <PageContext.Provider value={value}>
      <SnackBarContextProvider>{children}</SnackBarContextProvider>
    </PageContext.Provider>
  );
};

export const usePageContext = () => {
  const pageContext = useContext(PageContext);

  if (!pageContext) throw new Error('No PageContext.Provider found when calling usePageContext.');
  return pageContext;
};

// const LANGQUERY = graphql`
//   query DefaultLang {
//     site {
//       siteMetadata {
//         defaultLanguage
//       }
//     }
//   }
// `;
