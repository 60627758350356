import React from 'react';
import { LocaleLink } from '../localeLink/LocaleLink';
import { Logo } from '../logo/Logo';
import { DeviceEnum } from '../../utils/enums';
import { NavbarMenu } from '../navbarMenu/NavbarMenu';
import * as styles from './navbarDesktop.module.scss';
import LanguageToggle from '../languageToggle/LanguageToggle';

export const NavbarDesktop = () => (
  <nav className={styles.wrapper}>
    <div className={styles.logo}>
      <LocaleLink to="/">
        <Logo />
      </LocaleLink>
    </div>
    <NavbarMenu device={DeviceEnum.desktop} />
    <LanguageToggle />
  </nav>
);
